import { useCallback, useMemo, useRef } from 'react'
import { RegisterOptions } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, FormControl, useMergeRefs } from '@chakra-ui/react'
import { extend, pick } from 'lodash'

import { EmailFieldBase } from '~shared/types/field'
import { FormResponseMode } from '~shared/types/form'
import { validateEmailDomains } from '~shared/utils/email-domain-validation'

import { createBaseValidationRules } from '~utils/fieldValidation'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import Input from '~components/Input'
import Textarea from '~components/Textarea'
import Toggle from '~components/Toggle'

import { usePublicLanguageStore } from '../../../../../../../public-form/usePublicLanguageStore'
import { CreatePageDrawerContentContainer } from '../../../../../common'
import { useCreateTabForm } from '../../../../useCreateTabForm'
import { SPLIT_TEXTAREA_TRANSFORM } from '../common/constants'
import { FormFieldDrawerActions } from '../common/FormFieldDrawerActions'
import { EditFieldProps } from '../common/types'
import { useEditFieldForm } from '../common/useEditFieldForm'

const EDIT_EMAIL_FIELD_KEYS = [
  'title',
  'description',
  'required',
  'isVerifiable',
  'autoReplyOptions',
] as const

type EditEmailProps = EditFieldProps<EmailFieldBase>

type EditEmailInputs = Pick<
  EmailFieldBase,
  typeof EDIT_EMAIL_FIELD_KEYS[number]
> & {
  hasAllowedEmailDomains: boolean
  allowedEmailDomains: string
}

const transformEmailFieldToEditForm = (
  field: EmailFieldBase,
): EditEmailInputs => {
  const allowedEmailDomains = field.allowedEmailDomains
  return {
    ...pick(field, EDIT_EMAIL_FIELD_KEYS),
    hasAllowedEmailDomains: allowedEmailDomains.length > 0,
    allowedEmailDomains: SPLIT_TEXTAREA_TRANSFORM.input(allowedEmailDomains),
  }
}

const transformEmailEditFormToField = (
  inputs: EditEmailInputs,
  originalField: EmailFieldBase,
): EmailFieldBase => {
  const { allowedEmailDomains, hasAllowedEmailDomains, ...rest } = inputs
  return extend({}, originalField, rest, {
    hasAllowedEmailDomains,
    // Clear allowedEmailDomains when toggled off.
    allowedEmailDomains: hasAllowedEmailDomains
      ? SPLIT_TEXTAREA_TRANSFORM.output(allowedEmailDomains)
      : [],
  })
}

export const EditEmail = ({ field }: EditEmailProps): JSX.Element => {
  const { t } = useTranslation()

  const { publicI18n, isPublicView } = usePublicLanguageStore(
    useCallback(
      (state) => ({
        publicI18n: state.publicI18n,
        isPublicView: state.isPublicView,
      }),
      [],
    ),
  )

  const {
    register,
    formState: { errors },
    buttonText,
    handleUpdateField,
    watch,
    isLoading,
    handleCancel,
  } = useEditFieldForm<EditEmailInputs, EmailFieldBase>({
    field,
    transform: {
      input: transformEmailFieldToEditForm,
      output: transformEmailEditFormToField,
    },
  })

  const watchedHasAllowedEmailDomains = watch('hasAllowedEmailDomains')
  const watchedHasAutoReply = watch('autoReplyOptions.hasAutoReply')

  const requiredValidationRule = useMemo(
    () =>
      createBaseValidationRules({ required: true }, isPublicView, publicI18n),
    [isPublicView, publicI18n],
  )

  const allowedEmailDomainsRegister = useMemo(
    () => register('hasAllowedEmailDomains'),
    [register],
  )
  const hasAllowedEmailDomainsRef = useRef<HTMLInputElement>(null)
  const mergedAllowedEmailDomainsRef = useMergeRefs(
    hasAllowedEmailDomainsRef,
    allowedEmailDomainsRegister.ref,
  )

  const emailDomainsValidation = useMemo<
    RegisterOptions<EditEmailInputs, 'allowedEmailDomains'>
  >(
    () => ({
      ...requiredValidationRule,
      validate: {
        noDuplicate: (value) => {
          const split = SPLIT_TEXTAREA_TRANSFORM.output(value)
          if (isPublicView)
            return (
              new Set(split).size === split.length ||
              publicI18n(
                'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditEmail.pleaseRemoveDuplicateEmailDomains',
              )
            )

          return (
            new Set(split).size === split.length ||
            t(
              'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditEmail.pleaseRemoveDuplicateEmailDomains',
            )
          )
        },
        noEmpty: (value) => {
          const split = SPLIT_TEXTAREA_TRANSFORM.output(value)
          return split.length > 0 || 'Please enter at least one email domain'
        },
        validEmailDomains: (value) => {
          const split = SPLIT_TEXTAREA_TRANSFORM.output(value)
          if (isPublicView)
            return (
              validateEmailDomains(split) ||
              publicI18n(
                'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditEmail.pleaseEnterOnlyValidEmailDomainsStartWithAt',
              )
            )

          return (
            validateEmailDomains(split) ||
            t(
              'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditEmail.pleaseEnterOnlyValidEmailDomainsStartWithAt',
            )
          )
        },
      },
    }),
    [requiredValidationRule, t, publicI18n, isPublicView],
  )

  const { data: form } = useCreateTabForm()
  const isPdfResponseEnabled = useMemo(
    () => form?.responseMode !== FormResponseMode.Encrypt,
    [form],
  )
  const pdfResponseToggleDescription = useMemo(() => {
    if (!isPdfResponseEnabled) {
      if (isPublicView)
        return publicI18n(
          'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.pdfDescript',
        )

      return t(
        'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.pdfDescript',
      )
    }
  }, [isPdfResponseEnabled, t, publicI18n, isPublicView])

  return (
    <CreatePageDrawerContentContainer>
      <FormControl isRequired isReadOnly={isLoading} isInvalid={!!errors.title}>
        <FormLabel>
          {t(
            'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.question',
          )}
        </FormLabel>
        <Input autoFocus {...register('title', requiredValidationRule)} />
        <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
      </FormControl>
      <FormControl
        isRequired
        isReadOnly={isLoading}
        isInvalid={!!errors.description}
      >
        <FormLabel>
          {t(
            'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.description',
          )}
        </FormLabel>
        <Textarea {...register('description')} />
        <FormErrorMessage>{errors?.description?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isReadOnly={isLoading}>
        <Toggle
          {...register('required')}
          label={t(
            'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.required',
          )}
        />
      </FormControl>
      <FormControl isReadOnly={isLoading}>
        <Toggle
          {...register('isVerifiable')}
          label={t(
            'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.otpVerify',
          )}
          description={t(
            'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.otpDescript',
          )}
        />
      </FormControl>
      <Box>
        <FormControl isReadOnly={isLoading}>
          <Toggle
            {...allowedEmailDomainsRegister}
            ref={mergedAllowedEmailDomainsRef}
            label={t(
              'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.restrictEmail',
            )}
            description={t(
              'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.restrictDescript',
            )}
            isDisabled={!isDomainToggleEnabled}
          />
        </FormControl>
        {watchedHasAllowedEmailDomains && (
          <FormControl
            isReadOnly={isLoading}
            isRequired
            isInvalid={!!errors.allowedEmailDomains}
            mt="1.5rem"
          >
            <FormLabel>
              {t(
                'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.domainAllowed',
              )}
            </FormLabel>
            <Textarea
              autoFocus
              {...register('allowedEmailDomains', emailDomainsValidation)}
              placeholder={'@data.gov.kh\n@agency.gov.kh'}
            />
            <FormErrorMessage>
              {errors?.allowedEmailDomains?.message}
            </FormErrorMessage>
          </FormControl>
        )}
      </Box>
      <Box>
        <FormControl isReadOnly={isLoading}>
          <Toggle
            {...register('autoReplyOptions.hasAutoReply')}
            description={t(
              'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.emailDescript',
            )}
            label={t(
              'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.emailConfirm',
            )}
          />
        </FormControl>
        {watchedHasAutoReply && (
          <>
            <FormControl isRequired isReadOnly={isLoading} mt="1.5rem">
              <FormLabel>
                {t(
                  'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.subject',
                )}
              </FormLabel>
              <Input
                autoFocus
                placeholder={t(
                  'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.defaultEmailSubject',
                )}
                {...register('autoReplyOptions.autoReplySubject')}
              />
            </FormControl>
            <FormControl isRequired isReadOnly={isLoading} mt="1.5rem">
              <FormLabel>
                {t(
                  'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.senderName',
                )}
              </FormLabel>
              <Input
                placeholder={t(
                  'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.defaultSender',
                )}
                {...register('autoReplyOptions.autoReplySender')}
              />
            </FormControl>
            <FormControl isReadOnly={isLoading} isRequired mt="1.5rem">
              <FormLabel>
                {t(
                  'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.content',
                )}
              </FormLabel>
              <Textarea
                placeholder={t(
                  'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.defaultEmail',
                )}
                {...register('autoReplyOptions.autoReplyMessage')}
              />
            </FormControl>
            <FormControl isReadOnly={isLoading} mt="1.5rem">
              <Toggle
                {...register('autoReplyOptions.includeFormSummary')}
                label={t(
                  'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.includePDF',
                )}
                description={pdfResponseToggleDescription}
                isDisabled={!isPdfResponseEnabled}
              />
            </FormControl>
          </>
        )}
      </Box>
      <FormFieldDrawerActions
        isLoading={isLoading}
        buttonText={buttonText}
        handleClick={handleUpdateField}
        handleCancel={handleCancel}
      />
    </CreatePageDrawerContentContainer>
  )
}
