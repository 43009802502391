import { useTranslation } from 'react-i18next'
import { Text } from '@chakra-ui/react'

import Button, { ButtonProps } from '~components/Button'

export interface ResendOtpButtonProps extends ButtonProps {
  timer: number
  isDisabled: boolean
  isLoading: boolean
  onButtonClick: () => void
}

// Exported for testing
export const ResendOtpButton = ({
  isDisabled,
  isLoading,
  onButtonClick,
  timer,
  ...buttonProps
}: ResendOtpButtonProps): JSX.Element => {
  const { i18n } = useTranslation()
  return (
    <Button
      isDisabled={isDisabled}
      isLoading={isLoading}
      onClick={onButtonClick}
      type="button"
      variant="reverse"
      colorScheme="primary"
      {...buttonProps}
    >
      {i18n.t('templates.resendOtpButton.resendOTP')}
      <Text as="span" data-chromatic="ignore">
        {timer > 0 && `${i18n.t('templates.resendOtpButton.in')} ${timer}s`}
      </Text>
    </Button>
  )
}
