import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import {
  AdminFormDto,
  AdminStorageFormDto,
  EndPageUpdateDto,
  FormPermission,
  FormPermissionsDto,
  PaymentsProductUpdateDto,
  PaymentsUpdateDto,
  StartPageUpdateDto,
} from '~shared/types/form/form'

import { DASHBOARD_ROUTE } from '~constants/routes'
import { useToast } from '~hooks/useToast'
import { HttpError } from '~services/ApiService'

import {
  SubmitEmailFormArgs,
  SubmitStorageFormArgs,
} from '~features/public-form/PublicFormService'
import { workspaceKeys } from '~features/workspace/queries'

import { Languages } from '../../../i18n/constants'
import { getVariationOfAOrAn } from '../../../utils/englishWordVariation'
import {
  submitEmailModeFormPreview,
  submitEmailModeFormPreviewWithFetch,
  submitStorageModeFormPreview,
  submitStorageModeFormPreviewWithFetch,
} from '../common/AdminViewFormService'
import { downloadFormIssue } from '../responses/FeedbackPage/issue/IssueService'
import { downloadFormReview } from '../responses/FeedbackPage/review/ReviewService'

import { useCollaboratorWizard } from './components/CollaboratorModal/CollaboratorWizardContext'
import { DropdownRole } from './components/CollaboratorModal/constants'
import { permissionsToRole } from './components/CollaboratorModal/utils'
import {
  updateFormEndPage,
  updateFormPaymentProducts,
  updateFormPayments,
  updateFormStartPage,
} from './AdminFormPageService'
import {
  removeSelfFromFormCollaborators,
  transferFormOwner,
  updateFormCollaborators,
} from './AdminViewFormService'
import { adminFormKeys } from './queries'

export type MutateAddCollaboratorArgs = {
  newPermission: FormPermission
  currentPermissions: FormPermissionsDto
}

export type MutateRemoveCollaboratorArgs = {
  permissionToRemove: FormPermission
  currentPermissions: FormPermissionsDto
}

export type DownloadFormFeedbackMutationArgs = {
  formId: string
  formTitle: string
}

export type DownloadFormIssuesMutationArgs = {
  formId: string
  formTitle: string
  count: number | undefined
}

enum FormCollaboratorAction {
  UPDATE,
  ADD,
  REMOVE,
  TRANSFER_OWNERSHIP,
  REMOVE_SELF,
}

export const useMutateCollaborators = () => {
  const { formId } = useCollaboratorWizard()
  if (!formId) throw new Error('No formId provided to useMutateCollaborators')

  const { i18n, t } = useTranslation()

  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const toast = useToast({ status: 'success', isClosable: true })

  const getTranslatedPermissionRole = (newPermission: FormPermission) => {
    const permissionToRole = permissionsToRole(newPermission)

    let permissionRole = ''
    if (permissionToRole === DropdownRole.Editor) {
      permissionRole = t('ownership.editor')
    } else if (permissionToRole === DropdownRole.Viewer) {
      permissionRole = t('ownership.viewer')
    } else {
      permissionRole = t('ownership.owner')
    }

    return permissionRole
  }

  const updateFormData = useCallback(
    (newData: FormPermissionsDto) => {
      queryClient.setQueryData(adminFormKeys.collaborators(formId), newData)
      // Only update adminForm if it already has prior data.
      queryClient.setQueryData<AdminFormDto | undefined>(
        adminFormKeys.id(formId),
        (oldData) =>
          oldData
            ? {
                ...oldData,
                permissionList: newData,
              }
            : undefined,
      )
    },
    [formId, queryClient],
  )
  const getMappedBadRequestErrorMessage = (
    formCollaboratorAction: FormCollaboratorAction,
    originalErrorMessage: string,
  ): string => {
    let badRequestErrorMessage
    switch (formCollaboratorAction) {
      case FormCollaboratorAction.ADD:
        badRequestErrorMessage = `${t(
          'features.adminForm.common.mutation.collaborator',
        )}`
        break
      case FormCollaboratorAction.TRANSFER_OWNERSHIP:
        badRequestErrorMessage = originalErrorMessage
        break
      default:
        badRequestErrorMessage = `${t(
          'features.adminForm.common.mutation.errorOccur',
        )}`
    }

    return badRequestErrorMessage
  }

  const getMappedDefaultErrorMessage = (
    formCollaboratorAction: FormCollaboratorAction,
  ): string => {
    let defaultErrorMessage
    switch (formCollaboratorAction) {
      case FormCollaboratorAction.ADD:
        defaultErrorMessage = t('features.adminForm.common.mutation.errorAdd')
        break
      case FormCollaboratorAction.UPDATE:
        defaultErrorMessage = t(
          'features.adminForm.common.mutation.errorUpdate',
        )
        break
      case FormCollaboratorAction.REMOVE:
        defaultErrorMessage = t(
          'features.adminForm.common.mutation.errorRemove',
        )
        break
      case FormCollaboratorAction.REMOVE_SELF:
        defaultErrorMessage = t(
          'features.adminForm.common.mutation.errorRemovingSelf',
        )
        break
      case FormCollaboratorAction.TRANSFER_OWNERSHIP:
        defaultErrorMessage = t(
          'features.adminForm.common.mutation.errorTransfer',
        )
        break
      //should not reach
      default:
        defaultErrorMessage = t('features.adminForm.common.mutation.error')
    }
    return defaultErrorMessage
  }

  const getMappedErrorMessage = useCallback(
    (
      error: Error,
      formCollaboratorAction: FormCollaboratorAction,
      requestEmail?: string,
    ): string => {
      // check if error is an instance of HttpError to be able to access status code of error
      if (error instanceof HttpError) {
        let errorMessage
        switch (error.code) {
          case 422:
            errorMessage = requestEmail
              ? `${requestEmail} ${t(
                  'features.adminForm.common.mutation.whitelisted',
                )}`
              : `${t('features.adminForm.common.mutation.error422')}`
            break
          case 400:
            errorMessage = getMappedBadRequestErrorMessage(
              formCollaboratorAction,
              error.message,
            )
            break
          default:
            errorMessage = getMappedDefaultErrorMessage(formCollaboratorAction)
        }
        return errorMessage
      }
      // if error is not of type HttpError return the error message encapsulated in Error object
      return error.message
    },
    [],
  )

  const handleSuccess = useCallback(
    ({
      newData,
      toastDescription,
    }: {
      newData: FormPermissionsDto
      toastDescription: React.ReactNode
    }) => {
      toast.closeAll()
      updateFormData(newData)

      // Show toast on success.
      toast({
        description: toastDescription,
      })
    },
    [toast, updateFormData],
  )

  const handleError = useCallback(
    (
      error: Error,
      formCollaboratorAction: FormCollaboratorAction,
      requestEmail?: string,
    ) => {
      toast.closeAll()
      toast({
        description: getMappedErrorMessage(
          error,
          formCollaboratorAction,
          requestEmail,
        ),
        status: 'danger',
      })
    },
    [getMappedErrorMessage, toast],
  )

  const mutateUpdateCollaborator = useMutation(
    ({
      permissionToUpdate,
      currentPermissions,
    }: {
      permissionToUpdate: FormPermission
      currentPermissions: FormPermissionsDto
    }) => {
      const index = currentPermissions.findIndex(
        (c) => c.email === permissionToUpdate.email,
      )
      if (index === -1)
        throw new Error(t('features.adminForm.common.mutation.updateNotExist'))
      const permissionListToUpdate = currentPermissions.slice()
      // Replace old permissions with new permission.
      permissionListToUpdate[index] = permissionToUpdate

      return updateFormCollaborators(formId, permissionListToUpdate)
    },
    {
      onSuccess: (newData, { permissionToUpdate }) => {
        const permissionRole = getTranslatedPermissionRole(permissionToUpdate)

        const toastDescription = t(
          'features.adminForm.common.mutation.updated',
          {
            permissionToUpdateEmail: permissionToUpdate.email,
            newPermission: permissionRole,
          },
        )
        handleSuccess({ newData, toastDescription })
      },
      onError: (error: Error, { permissionToUpdate }) => {
        handleError(
          error,
          FormCollaboratorAction.UPDATE,
          permissionToUpdate.email,
        )
      },
    },
  )

  const mutateAddCollaborator = useMutation(
    ({ newPermission, currentPermissions }: MutateAddCollaboratorArgs) => {
      const rebuiltPermissions = [newPermission].concat(currentPermissions)
      return updateFormCollaborators(formId, rebuiltPermissions)
    },
    {
      onSuccess: (newData, { newPermission }) => {
        const permissionRole = getTranslatedPermissionRole(newPermission)

        const toastDescription = t('features.adminForm.common.mutation.added', {
          newPermissionEmail: newPermission.email,
          permissionRole:
            i18n.resolvedLanguage === Languages.English
              ? getVariationOfAOrAn(permissionRole, false)
              : permissionRole,
        })
        handleSuccess({ newData, toastDescription })
      },
      onError: (error: Error, { newPermission }) => {
        handleError(error, FormCollaboratorAction.ADD, newPermission.email)
      },
    },
  )

  const mutateRemoveCollaborator = useMutation(
    ({
      permissionToRemove,
      currentPermissions,
    }: MutateRemoveCollaboratorArgs) => {
      const filteredList = currentPermissions.filter(
        (c) => c.email !== permissionToRemove.email,
      )
      return updateFormCollaborators(formId, filteredList)
    },
    {
      onSuccess: (newData, { permissionToRemove }) => {
        // TODO: Decide if we want to allow redo (via readding permission)
        const toastDescription = `${permissionToRemove.email} ${t(
          'features.adminForm.common.mutation.remove',
        )}`
        handleSuccess({ newData, toastDescription })
      },
      onError: (error: Error) => {
        handleError(error, FormCollaboratorAction.REMOVE)
      },
    },
  )

  const mutateTransferFormOwnership = useMutation(
    (newOwnerEmail: string) => transferFormOwner(formId, newOwnerEmail),
    {
      onSuccess: (newData) => {
        toast.closeAll()
        // Show toast on success.
        toast({
          description: `${newData.form.admin.email} ${t(
            'features.adminForm.common.mutation.owner',
          )}`,
        })

        // Update cached data.
        queryClient.setQueryData(
          adminFormKeys.collaborators(formId),
          newData.form.permissionList,
        )
        queryClient.setQueryData<AdminFormDto | undefined>(
          adminFormKeys.id(formId),
          newData.form,
        )
      },
      onError: (error: Error) => {
        handleError(error, FormCollaboratorAction.TRANSFER_OWNERSHIP)
      },
    },
  )

  const mutateRemoveSelf = useMutation(
    () => removeSelfFromFormCollaborators(formId),
    {
      onSuccess: () => {
        toast({
          description: t('features.adminForm.common.mutation.removeSelf'),
        })

        // Remove all related queries from cache.
        queryClient.removeQueries(adminFormKeys.id(formId))
        queryClient.invalidateQueries(workspaceKeys.all)

        navigate(DASHBOARD_ROUTE)
      },
      onError: (error: Error) => {
        handleError(error, FormCollaboratorAction.REMOVE_SELF)
      },
    },
  )

  return {
    mutateAddCollaborator,
    mutateUpdateCollaborator,
    mutateRemoveCollaborator,
    mutateTransferFormOwnership,
    mutateRemoveSelf,
  }
}

export const useMutateFormPage = () => {
  const { formId } = useParams()
  if (!formId) throw new Error('No formId provided')

  const { t } = useTranslation()

  const queryClient = useQueryClient()
  const toast = useToast({ status: 'success', isClosable: true })

  const handleError = useCallback(
    (error: Error) => {
      toast.closeAll()
      toast({
        description: error.message,
        status: 'danger',
      })
    },
    [toast],
  )

  const startPageMutation = useMutation(
    (startPage: StartPageUpdateDto) => updateFormStartPage(formId, startPage),
    {
      onSuccess: (newData) => {
        toast.closeAll()
        queryClient.setQueryData<AdminFormDto | undefined>(
          adminFormKeys.id(formId),
          (oldData) =>
            oldData ? { ...oldData, startPage: newData } : undefined,
        )
        toast({
          description: t(
            'features.adminForm.common.mutation.editHeaderAndInstructions',
          ),
        })
      },
      onError: handleError,
    },
  )

  const endPageMutation = useMutation(
    (endPage: EndPageUpdateDto) => updateFormEndPage(formId, endPage),
    {
      onSuccess: (newData) => {
        toast.closeAll()
        queryClient.setQueryData<AdminFormDto | undefined>(
          adminFormKeys.id(formId),
          (oldData) => (oldData ? { ...oldData, endPage: newData } : undefined),
        )
        toast({
          description: t('features.adminForm.common.mutation.updateThank'),
        })
      },
      onError: handleError,
    },
  )

  const paymentsMutation = useMutation(
    (payments_field: PaymentsUpdateDto) =>
      updateFormPayments(formId, payments_field),
    {
      onSuccess: (newData) => {
        toast.closeAll()
        queryClient.setQueryData<AdminStorageFormDto | undefined>(
          adminFormKeys.id(formId),
          (oldData) =>
            oldData ? { ...oldData, payments_field: newData } : undefined,
        )
        toast({
          description: 'The payment was updated.',
        })
      },
      onError: handleError,
    },
  )

  const paymentsProductMutation = useMutation(
    (products: PaymentsProductUpdateDto) =>
      updateFormPaymentProducts(formId, products),
    {
      onSuccess: (newData) => {
        toast.closeAll()
        queryClient.setQueryData<AdminStorageFormDto | undefined>(
          adminFormKeys.products(formId, newData),
          (oldData) =>
            oldData
              ? {
                  ...oldData,
                  payments_field: {
                    ...oldData.payments_field,
                    products: newData,
                  },
                }
              : undefined,
        )
        toast({
          description: 'Payments product was updated.',
        })
      },
      onError: handleError,
    },
  )

  return {
    startPageMutation,
    endPageMutation,
    paymentsMutation,
    paymentsProductMutation,
  }
}

export const usePreviewFormMutations = (formId: string) => {
  const submitEmailModeFormMutation = useMutation(
    (args: Omit<SubmitEmailFormArgs, 'formId'>) => {
      return submitEmailModeFormPreview({ ...args, formId })
    },
  )

  const submitStorageModeFormMutation = useMutation(
    (args: Omit<SubmitStorageFormArgs, 'formId'>) => {
      return submitStorageModeFormPreview({ ...args, formId })
    },
  )

  // TODO (#5826): Fallback mutation using Fetch. Remove once network error is resolved
  const submitEmailModeFormFetchMutation = useMutation(
    (args: Omit<SubmitEmailFormArgs, 'formId'>) => {
      return submitEmailModeFormPreviewWithFetch({ ...args, formId })
    },
  )

  const submitStorageModeFormFetchMutation = useMutation(
    (args: Omit<SubmitStorageFormArgs, 'formId'>) => {
      return submitStorageModeFormPreviewWithFetch({ ...args, formId })
    },
  )

  return {
    submitEmailModeFormMutation,
    submitStorageModeFormMutation,
    submitEmailModeFormFetchMutation,
    submitStorageModeFormFetchMutation,
  }
}

export const useFormFeedbackMutations = () => {
  const { t } = useTranslation()
  const toast = useToast({ status: 'success', isClosable: true })

  const handleError = useCallback(
    (error: Error) => {
      toast.closeAll()
      toast({
        description: error.message,
        status: 'danger',
      })
    },
    [toast],
  )

  const downloadFormFeedbackMutation = useMutation(
    ({ formId, formTitle }: DownloadFormFeedbackMutationArgs) =>
      downloadFormReview(formId, formTitle),
    {
      onSuccess: () => {
        toast({
          description: t('feedback.feedbackDownload'),
        })
      },
      onError: handleError,
    },
  )

  return { downloadFormFeedbackMutation }
}

export const useFormIssueMutations = () => {
  const toast = useToast({ status: 'success', isClosable: true })

  const handleError = useCallback(
    (error: Error) => {
      toast.closeAll()
      toast({
        description: error.message,
        status: 'danger',
      })
    },
    [toast],
  )

  const downloadFormIssuesMutation = useMutation(
    ({ formId, formTitle, count }: DownloadFormIssuesMutationArgs) =>
      downloadFormIssue(formId, formTitle, count || 0),
    {
      onSuccess: () => {
        toast({
          description: 'Form issues download started',
        })
      },
      onError: handleError,
    },
  )

  return { downloadFormIssueMutation: downloadFormIssuesMutation }
}
