export const textStyles = {
  'main-title-1': {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '3.6rem',
    lineHeight: '5.5rem',
    letterSpacing: '-0.022em',
    fontFeatureSettings: "'cv05' on",
  },

  'main-title-1-mobile': {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '2.5rem',
    lineHeight: '3.5rem',
    letterSpacing: '-0.022em',
    fontFeatureSettings: "'cv05' on",
  },

  'display-1': {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '4rem',
    lineHeight: '4.5rem',
    letterSpacing: '-0.022em',
    fontFeatureSettings: "'cv05' on",
  },
  'display-1-mobile': {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '2.5rem',
    lineHeight: '3rem',
    letterSpacing: '-0.022em',
    fontFeatureSettings: "'cv05' on",
  },
  'display-2': {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '2.5rem',
    lineHeight: '3rem',
    letterSpacing: '-0.022em',
    fontFeatureSettings: "'cv05' on",
  },
  'display-2-mobile': {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '2rem',
    lineHeight: '2.5rem',
    letterSpacing: '-0.017em',
    fontFeatureSettings: "'cv05' on",
  },
  h1: {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '3rem',
    lineHeight: '4.0rem',
    letterSpacing: '-0.022em',
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
  },
  'h1-mobile': {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '2rem',
    lineHeight: '3.0rem',
    letterSpacing: '-0.017em',
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
  },
  h2: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1.5rem',
    lineHeight: '2.5rem',
    letterSpacing: '-0.019em',
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
  },
  h3: {
    fontWeight: 600,
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    letterSpacing: '-0.017em',
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
  },
  h4: {
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    letterSpacing: '-0.014em',
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
  },
  h5: {
    fontWeight: 600,
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    letterSpacing: '-0.014em',
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
  },
  h6: {
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    letterSpacing: '-0.014em',
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
  },
  'subhead-1': {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    letterSpacing: '-0.011em',
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
  },
  'subhead-2': {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    letterSpacing: '-0.006em',
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
  },
  'subhead-3': {
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.08em',
    textTransform: 'uppercase',
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
  },
  'body-1': {
    fontStyle: 'normal',
    fontWeight: 460,
    fontSize: '1.0625rem',
    lineHeight: '1.5rem',
    letterSpacing: '-0.011em',
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
  },
  'body-2': {
    fontStyle: 'normal',
    fontWeight: 460,
    fontSize: '0.975rem',
    lineHeight: '1.25rem',
    letterSpacing: '-0.006em',
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
  },
  'caption-1': {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    letterSpacing: 0,
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
  },
  'caption-2': {
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 460,
    lineHeight: '1rem',
    letterSpacing: 0,
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
  },
  legal: {
    fontStyle: 'normal',
    fontWeight: 460,
    fontSize: '0.625rem',
    lineHeight: '0.75rem',
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
  },
  monospace: {
    fontStyle: 'normal',
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'zero' on, 'cv05' on",
  },

  //fonts size custom
  'display-1-mobile-custom': {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '1.75rem',
    lineHeight: '2.5rem',
    letterSpacing: '-0.022em',
    fontFeatureSettings: "'cv05' on",
  },

  'display-1-custom': {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '2.5rem',
    lineHeight: '3.4375rem',
    letterSpacing: '-0.022em',
    fontFeatureSettings: "'cv05' on",
  },
  'body-1-custom': {
    fontStyle: 'normal',
    fontWeight: 460,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    letterSpacing: '-0.011em',
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
  },

  'body-1-mobile-custom': {
    fontStyle: 'normal',
    fontWeight: 460,
    fontSize: '0.8125rem',
    lineHeight: '1.375rem',
    letterSpacing: '-0.011em',
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
  },

  'display-2-mobile-custom': {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1.4375rem',
    lineHeight: '2rem',
    letterSpacing: '-0.017em',
    fontFeatureSettings: "'cv05' on",
  },

  'display-2-custom': {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '2.1875rem',
    lineHeight: '3rem',
    letterSpacing: '-0.022em',
    fontFeatureSettings: "'cv05' on",
  },
  'h4-custom': {
    fontWeight: 600,
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    letterSpacing: '-0.014em',
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'cv05' on",
  },
}
