import { SVGProps } from 'react'
import { chakra } from '@chakra-ui/react'

export const FormNotFoundSvgr = chakra((props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={231}
    height={321}
    fill="none"
    viewBox="0 0 648 633"
  >
    <g clipPath="url(#clip0_623_15277)">
      <mask
        id="mask0_623_15277"
        style={{ maskType: 'luminance' }}
        width="648"
        height="633"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M647.636 0H0v632.174h647.636V0z"></path>
      </mask>
      <g mask="url(#mask0_623_15277)">
        <path
          fill="#fff"
          d="M631.728 631H235c-2.704-.003-9.097-4.027-11.009-5.847-1.912-1.82-2.988-4.288-2.991-6.862V155.709c.003-2.574.588-5.889 2.5-7.709 1.912-1.82 2.796-4.497 5.5-4.5l203-2.5 203 1c2.704.003 7.097 5.027 9.009 6.847 1.912 1.82 2.988 4.288 2.991 6.862v462.582c-.003 2.574-1.079 5.042-2.991 6.862-1.912 1.82-9.577 5.844-12.281 5.847z"
        ></path>
        <path
          fill="#fff"
          d="M438.451 104.999L206.59 165.435a15.018 15.018 0 00-15 15L225 563.998l-72.5 22.5 41.5-13.5-44.55 14c-2.029.619-6.89-4.586-8.763-5.582a8.007 8.007 0 01-3.897-4.721L9.45 160.745C8.83 158.717.004 132.872 1 130.999c.996-1.874 3.472-10.879 5.5-11.5l29-9.5 92.55-28.487L116 113.511 263.5 40l76-22.5 48.5-15 9-2 8.451 6c1.004-.309 8.503 23.9 9.549 24-.951-.09-.428 1.008.5 1.5.5 2-.218 1.688.451 2.498.668.81 19.591 63.485.15-.016l34.4 106.336-12.05-35.819z"
        ></path>
        <path
          fill="#F2F2F2"
          d="M411.146 142.174H236.637a15.015 15.015 0 00-15 15v387.85l-2 .61-42.81 13.11a8.01 8.01 0 01-9.99-5.31l-127.34-415.95a8.003 8.003 0 015.31-9.99l65.97-20.2 191.25-58.54 65.97-20.2a7.987 7.987 0 019.99 5.3l32.55 106.32.609 2z"
        ></path>
        <path
          fill="#3F3D56"
          d="M449.226 140.174l-39.23-128.14A16.985 16.985 0 00395.366.086a16.995 16.995 0 00-6.6.668l-92.75 28.39-191.24 58.55-92.75 28.4a17.016 17.016 0 00-11.28 21.23l134.08 437.93a17.028 17.028 0 0016.261 12.03c1.684.001 3.36-.252 4.969-.75l63.58-19.46 2-.62v-2.09l-2 .61-64.17 19.65a15.017 15.017 0 01-18.73-9.95L2.666 136.734a14.98 14.98 0 019.95-18.73l92.75-28.4 191.241-58.54 92.75-28.4a15.151 15.151 0 014.409-.66 15.016 15.016 0 0114.321 10.61l39.049 127.56.62 2h2.081l-.611-2z"
        ></path>
        <path
          fill="#1C4076"
          stroke="#fff"
          strokeWidth="5"
          d="M104.055 73.698l-.001.001a6.486 6.486 0 00-.729 2.429c-.082.85.005 1.707.255 2.523l12.88 42.069s0 0 0 0a6.515 6.515 0 006.222 4.601l-18.627-51.623zm0 0c.4-.753.945-1.42 1.604-1.964h.001m-1.605 1.964l1.605-1.965m0 0a6.478 6.478 0 012.232-1.2h.001m-2.233 1.2l2.233-1.2m0 0L283.83 16.67s0 0 0 0a6.51 6.51 0 018.12 4.311s0 0 0 0v.002l12.88 42.068s0 0 0 0a6.51 6.51 0 01-4.313 8.121h-.001l-175.937 53.864h0m-16.686-54.503l16.686 54.503m0 0a6.51 6.51 0 01-1.897.285l1.897-.285zM207.653 24.955c0 9.665-7.834 17.5-17.5 17.5-9.664 0-17.5-7.835-17.5-17.5s7.836-17.5 17.5-17.5c9.666 0 17.5 7.835 17.5 17.5z"
        ></path>
        <path
          fill="#fff"
          d="M190.154 37.62c6.994 0 12.664-5.67 12.664-12.665 0-6.995-5.67-12.665-12.664-12.665-6.995 0-12.665 5.67-12.665 12.665 0 6.994 5.67 12.664 12.665 12.664z"
        ></path>
        <path
          fill="#E6E6E6"
          d="M602.637 582.174h-338a8.511 8.511 0 01-8.5-8.5v-405a8.51 8.51 0 018.5-8.5h338a8.508 8.508 0 018.5 8.5v405a8.509 8.509 0 01-8.5 8.5z"
        ></path>
        <path
          fill="#3F3D56"
          d="M447.137 140.174h-210.5a17.022 17.022 0 00-17 17v407.8l2-.61v-407.19a15.017 15.017 0 0115-15h211.12l-.62-2zm183.5 0h-394a17.022 17.022 0 00-17 17v458a17.022 17.022 0 0017 17h394a17.032 17.032 0 0012.014-4.986 17.03 17.03 0 004.986-12.014v-458a17.023 17.023 0 00-17-17zm15 475a15.018 15.018 0 01-15 15h-394a15.02 15.02 0 01-15-15v-458a15.017 15.017 0 0115-15h394a15.02 15.02 0 0115 15v458z"
        ></path>
        <path
          fill="#1C4076"
          stroke="#fff"
          strokeWidth="5"
          d="M337.043 179.767h0a6.503 6.503 0 01-1.906-4.594v-43.998a6.507 6.507 0 016.501-6.501h183.997a6.51 6.51 0 016.502 6.503v43.994a6.51 6.51 0 01-6.501 6.503H341.637a6.516 6.516 0 01-4.594-1.907zM451.137 105.174c0 9.665-7.836 17.5-17.5 17.5-9.666 0-17.5-7.835-17.5-17.5s7.834-17.5 17.5-17.5c9.664 0 17.5 7.835 17.5 17.5z"
        ></path>
        <path
          fill="#fff"
          d="M433.636 117.356c6.728 0 12.182-5.454 12.182-12.182 0-6.728-5.454-12.182-12.182-12.182-6.728 0-12.182 5.454-12.182 12.182 0 6.728 5.454 12.182 12.182 12.182z"
        ></path>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_623_15277">
        <path fill="#fff" d="M0 0H648V633H0z"></path>
      </clipPath>
    </defs>
  </svg>
))
