import create from 'zustand'
import { devtools } from 'zustand/middleware'

export type BadgeStore = {
  name: string
  setName: (name: string) => void
}

export const useBadgeStore = create<BadgeStore>()(
  devtools((set, get) => ({
    name: '',
    setName: (name) => {
      set({ name })
    },
  })),
)

export const nameSelector = (state: BadgeStore): BadgeStore['name'] =>
  state.name

export const setNameSelector = (state: BadgeStore): BadgeStore['setName'] =>
  state.setName
