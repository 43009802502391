import i18n from '~/i18n/i18n'

export const REQUIRED_ERROR = i18n.t<string>('validate.fieldRequired')

export const INVALID_EMAIL_ERROR = i18n.t<string>('validate.invalidEmail')
export const INVALID_EMAIL_DOMAIN_ERROR = i18n.t<string>(
  'validate.invalidDomain',
)

export const INVALID_DROPDOWN_OPTION_ERROR = i18n.t<string>(
  'validate.invalidDropDown',
)

export const CANNOT_TRANSFER_OWNERSHIP_TO_SELF =
  'You cannot transfer ownership to yourself'
export const INVALID_COUNTRY_REGION_OPTION_ERROR =
  'Please select a valid country/region from the dropdown list'
