import { useTranslation } from 'react-i18next'
import { Flex, Text } from '@chakra-ui/react'

import { GUIDE_WEBHOOKS } from '~constants/links'
import Link from '~components/Link'

import { SettingsUnsupportedSvgr } from '~features/admin-form/settings/svgrs/SettingsUnsupportedSvgr'

export const WebhooksUnsupportedMsg = (): JSX.Element => {
  const { i18n } = useTranslation()
  return (
    <Flex justify="center" flexDir="column" textAlign="center">
      <Text textStyle="h2" as="h2" color="primary.500" mb="1rem">
        {i18n.t<string>(
          'features.adminForm.settings.components.webhooksUnsupportedMsg.unavail',
        )}
      </Text>
      <Text textStyle="body-1" color="secondary.500" mb="2.5rem">
        {i18n.t<string>(
          'features.adminForm.settings.components.webhooksUnsupportedMsg.webhookDescript',
        )}{' '}
        <Link isExternal href={GUIDE_WEBHOOKS}>
          {i18n.t<string>(
            'features.adminForm.settings.components.webhooksUnsupportedMsg.readMore',
          )}
        </Link>
      </Text>
      <SettingsUnsupportedSvgr />
    </Flex>
  )
}
