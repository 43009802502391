import { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormControl, Stack, useBreakpointValue } from '@chakra-ui/react'

import { Languages } from '~/i18n/constants'

import Button from '~components/Button'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import Input from '~components/Input'
import ResendOtpButton from '~templates/ResendOtpButton'

export type OtpFormInputs = {
  otp: string
}

interface OtpFormProps {
  email: string
  otpPrefix: string
  onSubmit: (inputs: OtpFormInputs) => Promise<void>
  onResendOtp: () => Promise<void>
}

export const OtpForm = ({
  email,
  otpPrefix,
  onSubmit,
  onResendOtp,
}: OtpFormProps): JSX.Element => {
  const { handleSubmit, register, formState, setError } =
    useForm<OtpFormInputs>()
  const { i18n } = useTranslation()
  const [khmerTranslation, setKhmerTranslation] = useState(false)
  const isMobile = useBreakpointValue({ base: true, xs: true, lg: false })

  const validateOtp = useCallback(
    (value: string) =>
      value.length === 6 || i18n.t('features.login.components.OtpForm.otp'),
    [],
  )

  const onSubmitForm = async (inputs: OtpFormInputs) => {
    return onSubmit(inputs).catch((e) => {
      setError('otp', { type: 'server', message: e.message })
    })
  }

  const TranslateErrorMessage = useMemo(() => {
    if (formState.errors.otp && i18n.language === Languages.Khmer) {
      if (
        formState.errors.otp.message === 'OTP is invalid. Please try again.'
      ) {
        setKhmerTranslation(true)
        return 'ពាក្យសម្ងាត់មិនត្រឹមត្រូវ។​ សូមព្យាយាមម្ដងទៀត។'
      } else if (
        formState.errors.otp.message ===
        'OTP has expired. Please request for a new OTP.'
      ) {
        setKhmerTranslation(true)
        return 'ពាក្យសម្ងាត់ហួសសុពលភាព​។​ សូមស្នើសោសម្ងាត់ថ្មី។'
      } else if (
        formState.errors.otp.message ===
        'You have hit the max number of attempts. Please request for a new OTP.'
      ) {
        setKhmerTranslation(true)
        return 'ការព្យាយាមចូលរបស់អ្នកបានឈានដល់ចំនួនអតិបរមា។ សូមស្នើសោសម្ងាត់ថ្មី។'
      }
      return
    }
  }, [formState.errors.otp, i18n.language])

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <FormControl isInvalid={!!formState.errors.otp} mb="2.5rem">
        <FormLabel isRequired htmlFor="otp">
          {`${i18n.t(
            'features.login.components.OtpForm.otpSentTo',
          )} ${email.toLowerCase()}`}
        </FormLabel>
        <Input
          type="text"
          maxLength={6}
          inputMode="numeric"
          autoComplete="one-time-code"
          autoFocus
          {...register('otp', {
            required: i18n.t<string>(
              'features.login.components.OtpForm.otpIsRequired',
            ),
            pattern: {
              value: /^[0-9\b]+$/,
              message: i18n.t('features.login.components.OtpForm.onlyNumber'),
            },
            validate: validateOtp,
          })}
          prefix={otpPrefix === undefined ? undefined : `${otpPrefix} -`}
        />
        {formState.errors.otp && khmerTranslation === false && (
          <FormErrorMessage>{formState.errors.otp.message}</FormErrorMessage>
        )}

        {formState.errors.otp && khmerTranslation && (
          <FormErrorMessage>{TranslateErrorMessage}</FormErrorMessage>
        )}
      </FormControl>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing="1rem"
        align="center"
      >
        <Button
          isFullWidth={isMobile}
          isLoading={formState.isSubmitting}
          type="submit"
        >
          {i18n.t('features.login.components.OtpForm.signIn')}
        </Button>
        <ResendOtpButton onResendOtp={onResendOtp} />
      </Stack>
    </form>
  )
}
